import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-taager-pagination',
  templateUrl: './taager-pagination.component.html',
  styleUrls: ['./taager-pagination.component.scss'],
  standalone: true,
  imports: [PaginationModule, FormsModule],
})
export class TaagerPaginationComponent implements OnInit {
  @Input() boundaryLinks: boolean;

  @Input() totalItems: number;

  @Input() itemsPerPage: number;

  @Input() maxSize: number;

  @Input() currentPage: number;

  @Input() previousText: string;

  @Input() nextText: string;

  @Input() firstText: string;

  @Input() lastText: string;

  @Input() scope: string;

  @Input() scrollToTop$: Observable<boolean>;

  @Output() pageChanged$: EventEmitter<boolean>;

  constructor() {
    this.pageChanged$ = new EventEmitter();
    this.scrollToTop$ = new Observable<boolean>();
  }

  ngOnInit(): void {
    this.listenForScrollToTopEvent();
  }

  private listenForScrollToTopEvent(): void {
    this.scrollToTop$.subscribe({
      next: (scrollToTop) => {
        if (scrollToTop) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      },
    });
  }

  public pageChanged($event: any): void {
    this.pageChanged$.emit($event);
  }
}
