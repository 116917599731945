import { DOCUMENT, formatDate, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Country } from '@features/country/domain';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { ToastrService } from 'ngx-toastr';
import { finalize, Subject, Subscription } from 'rxjs';
import { LeadListFilters, LeadModel } from 'src/app/core/domain/lead.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetLeadsListUseCase } from 'src/app/core/usecases/leads/get-leads-list.usecase';
import { countriesList } from '../../../../v3/features/country/data';
import { dateTypeValidator } from '../../profile/shared/validators/date-type.validator';
import { fromDateToDateValidator } from '../../profile/shared/validators/from-date-to-date.validator';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { TaagerPaginationComponent } from '../../shared/components/taager-pagination/taager-pagination.component';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { UtilityService } from '../../shared/services/utility.service';
import { DateWrapperUtility } from '../../shared/utilities/date-wrapper.utility';
import { LeadFiltersComponent } from '../lead-filters/lead-filters.component';
import { LeadCardComponent } from './lead-card/lead-card.component';

@Component({
  selector: 'app-leads-list',
  templateUrl: './leads-list.component.html',
  styleUrls: ['./leads-list.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    LeadFiltersComponent,
    LoaderComponent,
    LeadCardComponent,
    NgFor,
    MatDatepickerModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    NgIf,
    TaagerPaginationComponent,
    NgClass,
  ],
})
export class LeadsListComponent extends LocalizedComponent {
  constructor(
    private _getLeadListUseCase: GetLeadsListUseCase,
    private _toastrService: ToastrService,
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private utilityService: UtilityService,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
    this.scrollToTop$ = new Subject<boolean>();
  }

  public loading = false;

  public showPagination = false;

  public showBoundaryLinks = true;

  public noOfItems: number;

  public currentPage = 1;

  public maxItemPerPage = 12;

  public scrollToTop$: Subject<boolean>;

  public triggerFilterReset: Subject<boolean> = new Subject();

  public leadList: LeadModel[];

  public leadsParams: LeadListFilters;

  public searchForm: any;

  public countries: Country[] = countriesList.list;

  public filterValues: any;

  public isEnglishLanguage = false;

  private _languageChangeSubscription: Subscription;

  public fromDateMax: any;

  public toDateMin: any;

  ngOnInit(): void {
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });

    this.searchFormInit();
    this.fetchLeadList();
    this.setMinMaxDates();
  }

  setMinMaxDates(): void {
    this.fromDateMax = DateWrapperUtility.getTheEndOfATimeUnit(new Date(), 'day');
  }

  public searchFormInit(): void {
    this.searchForm = new FormGroup(
      {
        platform: new FormControl(null),
        status: new FormControl(null),
        addressLength: new FormControl(null),
        country: new FormControl(null),
        assignedAdmin: new FormControl(null),
        hoursSince: new FormControl(null),
        conversionFailedAttemptsNum: new FormControl(null),
        leadDateSince: new FormControl(null, [dateTypeValidator]),
        leadDateTo: new FormControl(null, [dateTypeValidator]),
        lastConversionFailedAttemptDate: new FormControl(null),
      },
      [fromDateToDateValidator('leadDateSince', 'leadDateTo')],
    );
  }

  public setMinForToDate(event: any): void {
    this.toDateMin = new Date(event.value);
  }

  public filterSearch(event: any, type: any): void {
    if (type === 'leadDateSince') {
      this.setMinForToDate(event);
    }
    const newDate = event.value ? formatDate(event.value, 'yyyy-MM-dd', 'en-US') : null;
    this.searchForm.get(type).setValue(newDate);
    this.fetchLeadList();
  }

  public clearSelection(event: any, type: any): void {
    event.stopPropagation();
    this.searchForm.get(type).setValue(null);
    this.fetchLeadList();
  }

  public onFilterSearch(event: any): void {
    this.filterValues = event;
    this.fetchLeadList();
  }

  public fetchLeadList(pageUpdate?: boolean): void {
    this.loading = true;
    this.leadsParams = {
      page: this.currentPage,
      pageSize: this.maxItemPerPage,
      platform: null,
      status: this.filterValues?.status || null,
      addressLength: null,
      hoursSince: null,
      merchantId: null,
      productId: null,
      assignedAdmin: null,
      country: this.searchForm.get('country').value,
      leadId: this.filterValues?.searchKey || null,
      conversionFailedAttemptsNum: null,
      leadDateSince: this.searchForm.get('leadDateSince').value,
      leadDateTo: this.searchForm.get('leadDateTo').value,
      lastConversionFailedAttemptDate: null,
    };
    this._getLeadListUseCase
      .execute(this.leadsParams)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (leadsList) => {
          this.noOfItems = leadsList.count;
          this.showPagination = !!this.noOfItems;
          this.leadList = leadsList.result;
          if (pageUpdate) {
            this.scrollToTop$.next(true);
          }
        },
        error: (err) => {
          this._toastrService.error(err.error?.msg);
        },
      });
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.fetchLeadList();
  }

  openChat(): void {
    const script = this._renderer.createElement('script');
    script.type = `text/javascript`;
    script.text = `window.fcWidget.open()`;
    this._renderer.appendChild(this.document.body, script);
  }

  viewMyLeadsExtract(): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'lead_extract_excel',
    });
    const tempParams = this.leadsParams;
    tempParams.pageSize = 10000;
    tempParams.page = 1;
    this._getLeadListUseCase
      .execute(tempParams)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (leadsList) => {
          const leadsKeyList: any[] = [];
          leadsList.result.forEach((lead: LeadModel) => {
            const singleLead = {
              leadID: lead.leadId,
              orderID: lead.orderId,
              productId: lead.productId,
              status: lead.status,
              receiverName: lead.leadDetails.customerName,
              receiverAddress: lead.leadDetails.customerAddress,
              city: lead.leadDetails.customerCity,
              phoneNumber: lead.leadDetails.customerPhoneNumber,
              leadDate: lead.leadDate,
              country: lead.country,
              rejectedReason: lead.rejectedReason,
              suspendedReason: lead.suspendedReason,
              failedAttemptNote: lead.conversionFailedAttempts[0]?.notes,
            };
            leadsKeyList.push(singleLead);
          });
          this.extractArray(leadsKeyList);
        },
        error: (err) => {
          this._toastrService.error(err.error?.msg);
        },
      });
  }

  public extractArray(leads: any): void {
    const keys = [
      'leadID',
      'orderID',
      'productId',
      'status',
      'receiverName',
      'receiverAddress',
      'city',
      'phoneNumber',
      'leadDate',
      'country',
      'rejectedReason',
      'suspendedReason',
      'failedAttemptNote',
    ];
    const extractArray: any[] = [];
    extractArray.push(keys.map((key) => key.split('.')[key.split('.').length - 1]));
    leads.map(async (lead: any) => {
      const subArray: any[] = [];
      await keys.forEach((key, index) => {
        if (lead[key]) {
          subArray.push(lead[key].toString().replace(',', ''));
        } else {
          subArray.push('');
        }
        if (index === keys.length - 1) {
          extractArray.push(subArray);
        }
      });
    });
    this.utilityService.extractToExcel(extractArray, 'leads.csv');
  }
}
