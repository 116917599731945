<div class="lead-card">
  <ng-container *ngIf="lead.status === 'lead_converted'">
    <div class="row">
      <div class="col-2">
        <div class="title-details">
          <p class="title">{{ 'ORDERS_PAGE.LEADS.ORDER_ID' | translate }}</p>
          <p class="value">
            <b>{{ lead.orderId }}</b>
          </p>
        </div>
      </div>
      <div class="col-6">
        <div class="title-details">
          <p class="info-status">{{ 'ORDERS_PAGE.LEADS.ORDER_DETAILS' | translate }}</p>
        </div>
      </div>
    </div>
    <hr />
  </ng-container>
  <div class="row">
    <div class="col-xl-4 col-lg-6">
      <div class="title-details">
        <p class="title">{{ 'ORDERS_PAGE.LEADS.LEAD_ID' | translate }}</p>
        <p class="value">{{ lead.leadId }}</p>
      </div>
    </div>
    <div class="col-3 minimize-flex">
      <div class="title-details">
        <p class="title">{{ 'ORDERS_PAGE.LEADS.LEAD_RECEIVED' | translate }}</p>
        <p class="value">{{ lead.leadDate }}</p>
      </div>
    </div>
    <div class="col-3">
      <div class="title-details">
        <p class="title">{{ 'ORDERS_PAGE.LEADS.LEAD_STATUS' | translate }}</p>
        <p
          class="value status"
          [ngClass]="{
            'success-status': lead.status === 'lead_received',
            'error-status': lead.status === 'lead_rejected' || lead.status === 'lead_out_of_stock',
            'converted-status': lead.status === 'lead_converted',
            'warning-status': lead.status === 'lead_suspended'
          }"
        >
          {{ lead.status | leadMapper | translate }}
        </p>
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div *ngFor="let cardField of cardFields" [class]="cardField.class">
      <p class="title">{{ cardField.title | translate }}</p>
      <p class="value">{{ cardField.value }}</p>
    </div>
  </div>
</div>
