export interface LeadModel {
  id: string;
  leadId: string;
  platform: string;
  status: LeadStatusList;
  taagerId: number;
  assignedAdminName: string;
  minutesSinceCreated: number;
  conversionAttempts: number;
  conversionFailedAttempts: FailedNotesModel[];
  conversionFailedAttemptsNum: number;
  orderId: string;
  productId: string;
  country: string;
  leadDate: string;
  ad: AdModel;
  adGroup: AdGroupModel;
  leadDetails: LeadCustomerDetailModel;
  campaign: CampaignModel;
  rejectedReason: string;
  suspendedReason: string;
}

export interface LeadListFilters {
  page: number;
  pageSize: number;
  platform: string | null;
  status: LeadStatusList;
  merchantId: number | null;
  productId: string | null;
  assignedAdmin: number | null;
  addressLength: string | null;
  country: string;
  leadId: string;
  hoursSince: string | null;
  conversionFailedAttemptsNum: string | null;
  leadDateSince: string;
  leadDateTo: string;
  lastConversionFailedAttemptDate: string | null;
}

export enum LeadStatus {
  lead_received = 'lead_received',
  lead_suspended = 'lead_suspended',
  lead_rejected = 'lead_rejected',
  lead_converted = 'lead_converted',
  lead_out_of_stock = 'lead_out_of_stock',
}

export type LeadStatusList =
  | LeadStatus.lead_received
  | LeadStatus.lead_suspended
  | LeadStatus.lead_rejected
  | LeadStatus.lead_converted
  | LeadStatus.lead_out_of_stock;

export interface LeadListPaginatedModel {
  count: number;
  result: LeadModel[];
}

export interface LeadDetailModel {
  id: string;
  leadId: string;
  leadDate: string;
  ad: AdModel;
  adGroup: AdGroupModel;
  campaign: CampaignModel;
  platform: string;
  status: string;
  country: string;
  productId: string;
  leadDetails: LeadCustomerDetailModel;
  suspendedReason: string;
  rejectedReason: string;
  merchant: LeadUserDetailModel;
  assignedAdmin: LeadUserDetailModel;
  orderId: string;
  conversionFailedAttempts: FailedNotesModel[];
  conversionFailedAttemptsNum: number;
  notes: string;
}

export interface FailedNotesModel {
  notes: string;
}

export interface AdModel {
  adId: string;
  adName: string;
}

export interface AdGroupModel {
  adGroupId: string;
  adGroupName: string;
}

export interface CampaignModel {
  campaignId: string;
  campaignName: string;
}

export interface LeadCustomerDetailModel {
  customerName: string;
  customerAddress: string;
  customerCity: string;
  customerPhoneNumber: string;
  adDetails: string;
}

export interface LeadUserDetailModel {
  taagerId: string;
  name: string;
}
