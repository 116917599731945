import { Injectable } from '@angular/core';
import { FailedOrderModel } from 'src/app/core/domain/order/failed-orders.model';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { FailedOrderSideEffect } from './failed-order.sideEffects';
import { FailedOrderViewEvents } from './failed-order.viewEvents';
import { FailedOrderViewState } from './failed-order.viewState';

@Injectable({ providedIn: 'root' })
export class FailedOrderPresenter extends BasePresenter<
  FailedOrderViewState,
  FailedOrderViewEvents,
  FailedOrderSideEffect
> {
  protected defaultViewState(): FailedOrderViewState {
    return {
      status: 'FAILED_ORDERS.STATUS',
      FailedOrder: {
        createdAt: '',
        totalPrice: 0,
        totalProfit: 0,
        phoneNumber: '',
        phoneNumber2: '',
        receiverName: '',
        province: '',
        country: '',
        notes: '',
        orderReceivedBy: '',
        failureReasonMessage: '',
        failureReasonCode: '',
        address: '',
      },
      isNoFailedOrders: false,
    };
  }

  private init(failedOrder: FailedOrderModel, numberOfFailedOrders: string): void {
    this.merge({
      FailedOrder: failedOrder,
      isNoFailedOrders: Number(numberOfFailedOrders) === 0,
    });
  }

  protected onViewEvent(event: FailedOrderViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.init(event.failedOrder, event.numberOfFailedOrders);
        break;
      }
    }
  }
}
