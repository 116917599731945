import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { countriesList, country } from '@features/country/data';
import { Country } from '@features/country/domain';
import { user } from '@features/user/data';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfoAlertComponent } from 'app/components/info-alert/info-alert.component';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import { finalize, forkJoin, Subject, Subscription } from 'rxjs';
import { ChildOrdersFilterModel } from 'src/app/core/domain/order/child-orders-filter.model';
import { FailedOrderModel } from 'src/app/core/domain/order/failed-orders.model';
import { OrderListParamModel } from 'src/app/core/domain/order/order-list.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetCountryVATByIsoCode3UseCase } from 'src/app/core/usecases/get-country-vat.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetChildOrdersUseCase } from 'src/app/core/usecases/order/get-child-orders.usecase';
import { GetFailedOrdersUseCase } from 'src/app/core/usecases/order/get-failed-orders.usecase';
import { GetOrdersListUseCase } from 'src/app/core/usecases/order/orders-list.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { ORDER_TABS } from 'src/app/presentation/shared/constants/tabs';
import { ProfileService } from 'src/app/presentation/shared/services/profile.service';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';
import { UtilityService } from 'src/app/presentation/shared/services/utility.service';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { TaagerPaginationComponent } from '../shared/components/taager-pagination/taager-pagination.component';
import { FAILED_ORDERS_USER_FEATURE, FEATURE_FLAGS, OrdersTab } from '../shared/constants';
import { ENGLISH_LANGUAGE } from '../shared/constants/country-language-codes-mapping.constants';
import {
  SHOW_MERCHANT_LEADS,
  WEB_ORDERS_REVAMP,
  WEB_TELE_SALES_ORDERS,
} from '../shared/constants/feature-flags';
import {
  ORDER_LIST_TYPE,
  ORDER_TYPE_TAAGER,
  ORDER_TYPE_TIKTOK,
} from '../shared/constants/order-statuses';
import { SiteTranslateService } from '../shared/services/translate.service';
import { cesLocationNotifierUtility } from '../shared/utilities/ces-location-notifier.utility';
import { featureAttributeAssign } from '../shared/utilities/feature-attribute-assign.utility';
import { ChildOrderOverviewComponent } from './child-order-overview/child-order-overview.component';
import { FailedOrderOverviewComponent } from './failed-order-overview/failed-order-overview.component';
import { GlobalOrderPreferencesDialogComponent } from './global-order-preferences-dialog/global-order-preferences-dialog.component';
import { LeadsListComponent } from './leads-list/leads-list.component';
import { OrderFiltersComponent } from './order-filters/order-filters.component';
import { OrderOverviewComponent } from './order-overview/order-overview.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  standalone: true,
  imports: [
    LoaderComponent,
    OrderFiltersComponent,
    NgFor,
    NgClass,
    NgIf,
    OrderOverviewComponent,
    ChildOrderOverviewComponent,
    TaagerPaginationComponent,
    TranslateModule,
    MatNativeDateModule,
    PaginationModule,
    LeadsListComponent,
    FailedOrderOverviewComponent,
    InfoAlertComponent,
  ],
  providers: [PaginationConfig],
})
export class OrdersComponent implements OnInit {
  public orders: any[] = [];

  public childOrders: any[] = [];

  public loading = true;

  public filterObject: any = {};

  public maxItemPerPage = 12;

  public showBoundaryLinks = true;

  public currentPage = 1;

  public showPagination: boolean;

  public noOfItems: number;

  public isMobile: boolean;

  public searchKey = '';

  public showAllOrders = true;

  public showVerifiedOrders: boolean | null = false;

  public ordersTabs = ORDER_TABS;

  public scrollToTop$: Subject<boolean>;

  public shouldShowTeleSales = false;

  public ordersTabsArray: OrdersTab[] = [
    { key: 'ALL_ORDERS_TAB', name: ORDER_TABS.ALL_ORDERS_TAB },
    { key: 'CURRENT_ORDERS_TAB', name: ORDER_TABS.CURRENT_ORDERS_TAB },
    { key: 'PREVIOUS_ORDERS_TAB', name: ORDER_TABS.PREVIOUS_ORDERS_TAB },
    { key: 'CHILD_ORDERS_TAB', name: ORDER_TABS.CHILD_ORDERS_TAB },
  ];

  public activeOrdersTab: any;

  public countries: Country[] = countriesList.list;

  public triggerFilterReset: Subject<boolean> = new Subject();

  private _cesLocationNotifierUtility = cesLocationNotifierUtility();

  public orderListType: { value: string; key: string; icon: string }[] = ORDER_LIST_TYPE;

  public orderTypeActive: string = ORDER_TYPE_TAAGER;

  public shouldShowMerchantLeads = false;

  public orderTypeLead = ORDER_TYPE_TIKTOK;

  public orderTypeTaager = ORDER_TYPE_TAAGER;

  public isEnglishLanguage: boolean;

  public languageChangeSubscription: Subscription;

  public failedOrders: FailedOrderModel[];

  public numberOfFailedOrders: number;

  public shouldShowOrdersRevamp = false;

  constructor(
    private profileService: ProfileService,
    private responsiveService: ResponsiveService,
    private routes: ActivatedRoute,
    private utilityService: UtilityService,
    private _getCountryVATByIsoCode3UseCase: GetCountryVATByIsoCode3UseCase,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _getChildOrdersUseCase: GetChildOrdersUseCase,
    private _dialog: MatDialog,
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _getFailedOrdersUseCase: GetFailedOrdersUseCase,
    private _getOrdersListUseCase: GetOrdersListUseCase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
  ) {
    this.scrollToTop$ = new Subject<boolean>();
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this.languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });
  }

  ngOnInit(): void {
    this.checkShouldShowOrdersRevamp();
    this.checkMerchantLeadsEnable();
    this.checkFailedOrdersEnabled();
    this._cesLocationNotifierUtility({ action: 'write', location: 'orders' });
    this.getIsMobile();

    if (this.isMobile) {
      this.maxItemPerPage = 3;
    }

    this.checkOrderIdRouteParams();
    this._checkShouldShowTeleSales();
    this.filterObject = this.shouldShowOrdersRevamp
      ? ({} as OrderListParamModel)
      : ({} as ChildOrdersFilterModel);
  }

  public checkMerchantLeadsEnable(): void {
    this._getFeatureAttributeUseCase.execute(SHOW_MERCHANT_LEADS).subscribe({
      next: (attribute) => {
        this.shouldShowMerchantLeads = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
  }

  ngOnDestroy(): void {
    this._cesLocationNotifierUtility({ action: 'write', location: ' ' });
  }

  getIsMobile(): void {
    this.responsiveService.getMobileStatus().subscribe({
      next: (isMobile) => {
        this.isMobile = isMobile;
      },
    });
  }

  checkFailedOrdersEnabled(): void {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.FAILED_ORDERS).subscribe((flag) => {
      const userHasFailedOrders = this._checkUserFeatureExistsUseCase.execute(
        FAILED_ORDERS_USER_FEATURE,
      );

      if (flag && userHasFailedOrders) {
        this.ordersTabsArray.push({
          key: 'FAILED_ORDERS_TAB',
          name: ORDER_TABS.FAILED_ORDERS_TAB,
          hasNumberOfOrders: true,
        });
        this.getFailedOrders();
      }
    });
  }

  checkOrderIdRouteParams(): void {
    const { orderId, taagerId } = this.routes.snapshot.params;

    if (orderId && taagerId) {
      this.filterObject = {
        orderId: '',
      };
      this.filterObject.orderId = `${taagerId}/${orderId}`;
      if (/^[MSR]/.test(orderId)) {
        this.switchTab(ORDER_TABS.CHILD_ORDERS_TAB);
      } else {
        this.switchTab(ORDER_TABS.ALL_ORDERS_TAB);
      }
    } else {
      this.switchTab(ORDER_TABS.ALL_ORDERS_TAB);
    }
  }

  public switchOrderType(type: any): void {
    this.orderTypeActive = type;
  }

  public switchTab(tabName: string): void {
    switch (tabName) {
      case ORDER_TABS.ALL_ORDERS_TAB:
        this.showAllOrders = true;
        this.showVerifiedOrders = null;
        this.activeOrdersTab = ORDER_TABS.ALL_ORDERS_TAB;
        break;
      case ORDER_TABS.CURRENT_ORDERS_TAB:
        this.showAllOrders = false;
        this.showVerifiedOrders = false;
        this.activeOrdersTab = ORDER_TABS.CURRENT_ORDERS_TAB;
        break;
      case ORDER_TABS.PREVIOUS_ORDERS_TAB:
        this.showAllOrders = false;
        this.showVerifiedOrders = true;
        this.activeOrdersTab = ORDER_TABS.PREVIOUS_ORDERS_TAB;
        break;
      case ORDER_TABS.CHILD_ORDERS_TAB:
        this.activeOrdersTab = ORDER_TABS.CHILD_ORDERS_TAB;
        break;
      case ORDER_TABS.FAILED_ORDERS_TAB:
        this.activeOrdersTab = ORDER_TABS.FAILED_ORDERS_TAB;
        break;
    }
  }

  public orderTabClicked(tabName: string): void {
    if (this.activeOrdersTab !== tabName) {
      this.currentPage = 1;
      this.resetFilterObject();
      this.switchTab(tabName);
      this.getOrders();
    }
  }

  public extractArray(orders: any): void {
    let keys: any;
    forkJoin(
      this.countries.map((cntry) => this._getCountryVATByIsoCode3UseCase.execute(cntry.isoCode3)),
    ).subscribe({
      next: (countriesVat) => {
        const shouldShowVat = countriesVat.some((countryVat) => countryVat > 0);
        if (this.activeOrdersTab !== ORDER_TABS.CHILD_ORDERS_TAB) {
          const vatKeys = shouldShowVat ? ['VAT', 'netProfit'] : [];
          keys = [
            'orderID',
            'receiverName',
            'status',
            'createdAt',
            'updatedAt',
            'productId',
            'productQuantity',
            'productPrice',
            'phoneNumber',
            'receiverName',
            'streetName',
            'province',
            'cashOnDelivery',
            'productProfit',
            'deliverySuspendedReason',
            'failedAttemptNote',
            'suspendedReason',
            'customerRejectedReason',
            'notes',
            'country',
            ...vatKeys,
            'pageName',
            'pageUrl',
            'orderSource',
          ];
        } else {
          keys = [
            'orderID',
            'parentOrderId',
            'orderIssueID',
            'product.name',
            'product.productQty',
            'product.productId',
            'product.productProfit',
            'product.productPrice',
            'status',
            'receiverName',
            'province',
            'streetName',
            'zone.name',
            'phoneNumber',
            'phoneNumber2',
            'cashOnDelivery',
            'pickupDate',
            'deliverySuspendedReason',
            'deliveryDate',
            'notes',
            'country',
          ];
        }
        const extractArray: any[] = [];
        extractArray.push(keys.map((key: any) => key.split('.')[key.split('.').length - 1]));
        orders.map(async (order: any) => {
          const subArray: any[] = [];
          await keys.forEach((key: any, index: any) => {
            if (key.split('.').length === 2) {
              if (order[key.split('.')[0]][key.split('.')[1]]) {
                subArray.push(
                  order[key.split('.')[0]][key.split('.')[1]].toString().replace(',', ''),
                );
              } else {
                subArray.push('');
              }
            } else {
              if (order[key]) {
                if (order[key].fromProductCheckout) {
                  order.orderSource = 'صفحة الهبوط';
                } else if (!order.orderSource?.fromProductCheckout) {
                  order.orderSource = null;
                }
                subArray.push(order[key].toString().replace(',', ''));
              } else {
                subArray.push('');
              }
            }
            if (index === keys.length - 1) {
              extractArray.push(subArray);
            }
          });
        });
        this.utilityService.extractToExcel(extractArray, 'orders.csv');
      },
    });
  }

  public viewMyOrdersExtract(): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'Order_extract_excel',
    });
    if (this.activeOrdersTab !== ORDER_TABS.CHILD_ORDERS_TAB) {
      const filter = {
        showAllOrders: true,
        ...this.filterObject,
      };
      this.profileService.getUserOrdersExtract(filter).subscribe({
        next: (orders) => {
          const allOrdersExtract = orders.reduce((acc: any[], order) => {
            const templateOrder: any = { ...order };
            acc = [
              ...acc,
              ...order.orderLines.reduce((spreadOrder: any, orderLine, index) => {
                templateOrder.productId = orderLine.productId;
                templateOrder.productQuantity = orderLine.quantity;
                templateOrder.productPrice = orderLine.totalPrice;
                templateOrder.productProfit = orderLine.totalMerchantProfit;
                templateOrder.VAT = -orderLine.vatProfit!;
                templateOrder.netProfit = orderLine.totalMerchantProfit - orderLine.vatProfit!;
                templateOrder.pageName = order.orderSource?.pageName;
                templateOrder.pageUrl = order.orderSource?.pageUrl;
                templateOrder.cashOnDelivery = index === 0 ? templateOrder.cashOnDelivery : null;
                /* eslint-disable-next-line */
                templateOrder.orderSource?.fromProductCheckout
                  ? (templateOrder.orderSource = order.orderSource)
                  : null;
                spreadOrder.push({ ...templateOrder });
                return spreadOrder;
              }, []),
            ];
            return acc;
          }, []);
          this.extractArray(allOrdersExtract);
        },
        error: (err) => {
          this.loading = false;
        },
      });
    } else {
      const filter = {
        ...this.filterObject,
        taagerID: user.id,
        pageSize: 10000,
        pageNum: 1,
      };
      this._getChildOrdersUseCase.execute(filter).subscribe({
        next: async (res: any) => {
          this.extractArray(res.data);
        },
      });
    }
  }

  pageChanged(event: any): void {
    if (this.currentPage !== event.page) {
      this.currentPage = event.page;
      this.getOrders(true);
    }
  }

  onSearchClicked(orderFilters: any): void {
    this.filterObject = { ...orderFilters };
    if (this.shouldShowOrdersRevamp) {
      this.filterObject.createdDateFrom = orderFilters.fromDate;
      this.filterObject.createdDateTo = orderFilters.toDate;
    }
    this.searchKey = orderFilters.searchKey;
    this.getOrders();
  }

  getOrders(onPageChange = false): void {
    this.orders = [];
    this.childOrders = [];
    if (
      this.activeOrdersTab !== ORDER_TABS.CHILD_ORDERS_TAB &&
      this.activeOrdersTab !== ORDER_TABS.FAILED_ORDERS_TAB
    ) {
      this.getUserOrders(onPageChange);
    } else if (this.activeOrdersTab === ORDER_TABS.FAILED_ORDERS_TAB) {
      this.getFailedOrders();
      this._logMixpanelEventUseCase.execute({
        eventName: 'failed-orders-tab-clicked',
      });
    } else {
      this.getUserChildOrders();
    }
  }

  getFailedOrders(): void {
    if (this.loading) return;

    const paginationInfo = {
      page: this.currentPage,
      pageSize: this.maxItemPerPage,
    };
    if (this.currentPage > 1) {
      this.scrollToTop$.next(true);
    }

    this.loading = true;

    this._getFailedOrdersUseCase
      .execute(paginationInfo)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (orders) => {
          this.showPagination = !!orders.count;
          this.noOfItems = orders.count;
          this.failedOrders = orders.failedOrders;
          this.numberOfFailedOrders = orders.count;
        },
      });
  }

  /*eslint-disable*/
  getUserOrders(onPageChange: boolean = false): void {
    if (this.searchKey) {
      this.switchTab(ORDER_TABS.ALL_ORDERS_TAB);
      this.shouldShowOrdersRevamp ? this.searchUserOrders() : this.searchUserOrdersByKey();
    } else {
      this.searchUserOrders(onPageChange);
    }
  }

  /* eslint-enable */
  getUserChildOrders(): void {
    if (this.searchKey) {
      this.searchChildOrdersByKey();
    } else {
      this.searchChildOrders();
    }
  }

  searchUserOrdersByKey(): void {
    this.loading = true;
    this.profileService
      .searchInUserOrders(this.maxItemPerPage, this.currentPage, this.searchKey)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.orders = res.data;
          this.noOfItems = res.count;
          this.showPagination = !!res.count;
          this.trackOrdersSearch(res.count);
        },
      });
  }

  searchChildOrdersByKey(): void {
    this.loading = true;

    this.profileService
      .searchInUserChildOrders({
        pageSize: this.maxItemPerPage,
        pageNum: this.currentPage,
        searchKey: this.searchKey,
      })
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.childOrders = res.data;
          this.noOfItems = res.count;
          this.showPagination = !!res.count;
        },
      });
  }

  private _onOrdersFetched(): void {
    this.scrollToTop$.next(true);
  }

  checkShouldShowOrdersRevamp(): void {
    this._getFeatureAttributeUseCase.execute(WEB_ORDERS_REVAMP).subscribe({
      next: (status) => {
        this.shouldShowOrdersRevamp = featureAttributeAssign(status, user.id, country.code);
        if (this.shouldShowOrdersRevamp) {
          this.ordersTabsArray = this.ordersTabsArray.filter(
            (tab) => tab.key !== 'PREVIOUS_ORDERS_TAB' && tab.key !== 'CURRENT_ORDERS_TAB',
          );
        }
        this.getOrders();
      },
    });
  }

  searchUserOrders(onPageChange = false): void {
    if (this.shouldShowOrdersRevamp) {
      let filter: OrderListParamModel = {
        page: this.currentPage,
        pageSize: this.maxItemPerPage,
        isOrderVerified: this.showVerifiedOrders,
        merhantTaagerId: user.id,
        createdDateFrom: this.filterObject?.createdDateFrom,
        createdDateTo: this.filterObject?.createdDateTo,
        country: this.filterObject?.country,
        ...this.filterObject,
      };
      if (this.searchKey) {
        filter = {
          ...filter,
          customerNameOrPhoneNumbers: this.searchKey,
        };
      }
      this._getOrdersListUseCase
        .execute(filter)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: (order) => {
            this.orders = order.orders;
            this.noOfItems = order.count;
            this.showPagination = !!order.count;
            this.trackOrdersSearch(order.count);
            if (onPageChange) {
              this._onOrdersFetched();
            }
          },
        });
    } else {
      const ordersFilter = {
        showAllOrders: this.showAllOrders,
        isOrderVerified: this.showVerifiedOrders,
        ...this.filterObject,
      };
      this.profileService
        .getUserOrders(this.maxItemPerPage, this.currentPage, ordersFilter)
        .subscribe({
          next: (res) => {
            this.orders = res.data;
            this.noOfItems = res.count;
            this.showPagination = !!res.count;
            this.trackOrdersSearch(res.count);
            if (onPageChange) {
              this._onOrdersFetched();
            }
          },
          complete: () => {
            this.loading = false;
          },
        });
    }
  }

  searchChildOrders(): void {
    this.loading = true;
    const childOrdersFilter = {
      showAllOrders: this.showAllOrders,
      isOrderVerified: this.showVerifiedOrders,
      taagerID: user.id,
      pageSize: this.maxItemPerPage,
      pageNum: this.currentPage,
      ...this.filterObject,
    };

    this._getChildOrdersUseCase
      .execute(childOrdersFilter)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (res: any) => {
          this.childOrders = res.data;
          this.showPagination = !!res.count;
          this.noOfItems = res.count;
        },
      });
  }

  trackOrdersSearch(orderCount: number): void {
    const { cntry, status, orderId, fromDate, toDate } = this.filterObject || {};

    if (this.searchKey) {
      this._logMixpanelEventUseCase.execute({
        eventName: 'Order_search_bar',
        payload: {
          Keyword: this.searchKey,
          'Orders Available': orderCount,
        },
      });
    } else if (cntry || status || orderId || fromDate || toDate) {
      this._logMixpanelEventUseCase.execute({
        eventName: 'Order_search_button',
        payload: {
          country,
          'Order Status': status,
          'Order Id': orderId,
          'From Date': String(fromDate),
          'To Date': String(toDate),
          'Orders Available': orderCount,
        },
      });
    }
  }

  resetFilterObject(): void {
    for (const key in this.filterObject) {
      if (this.filterObject.hasOwnProperty(key)) {
        this.filterObject[key] = null;
        this.triggerFilterReset.next(true);
      }
    }
  }

  openOrderPreferencesDialog(): void {
    this._dialog.open(GlobalOrderPreferencesDialogComponent, {
      direction: this._siteTranslateService.currentDir,
      width: '700px',
    });
  }

  private _checkShouldShowTeleSales(): void {
    this._getFeatureAttributeUseCase.execute(WEB_TELE_SALES_ORDERS).subscribe((attribute) => {
      this.shouldShowTeleSales = featureAttributeAssign(attribute, user.id);
    });
  }
}
