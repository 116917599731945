import { DatePipe, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { GetFeatureFlagUsecase } from 'app/core/usecases/get-feature-flag.usecase';
import { OrderItemDialogComponent } from 'app/presentation/orders/order-item-dialog/order-item-dialog.component';
import { FailedOrderModel } from 'src/app/core/domain/order/failed-orders.model';
import { BaseComponent } from '../../base/base.component';
import { dateFormat, VIEW_FAILED_ORDER_DETAILS_BTN } from '../../shared/constants';
import { FailedOrderPresenter } from './failed-order-presenter/failed-order.presenter';
import { FailedOrderSideEffect } from './failed-order-presenter/failed-order.sideEffects';
import { FailedOrderViewEvents } from './failed-order-presenter/failed-order.viewEvents';
import { FailedOrderViewState } from './failed-order-presenter/failed-order.viewState';

@Component({
  selector: 'app-failed-order-overview',
  standalone: true,
  imports: [TranslateModule, NgIf, DatePipe],
  templateUrl: './failed-order-overview.component.html',
  styleUrls: ['./failed-order-overview.component.scss'],
  providers: [FailedOrderPresenter],
})
export class FailedOrderOverviewComponent extends BaseComponent<
  FailedOrderPresenter,
  FailedOrderViewState,
  FailedOrderViewEvents,
  FailedOrderSideEffect
> {
  @Input() failedOrder: FailedOrderModel;

  @Input() numberOfFailedOrders: number;

  @Input() draft = false;

  @Input() childOrder: any;

  public isViewFailedOrderDetailsBtnEnabled = false;

  public statusStage = '';

  public dateFormat = dateFormat;

  public presenter: FailedOrderPresenter = inject(FailedOrderPresenter);

  constructor(private dialog: MatDialog, private _getFeatureFlagUseCase: GetFeatureFlagUsecase) {
    super();

    this._getFeatureFlagUseCase.execute(VIEW_FAILED_ORDER_DETAILS_BTN).subscribe((flag) => {
      this.isViewFailedOrderDetailsBtnEnabled = flag;
    });
  }

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
      failedOrder: this.failedOrder,
      numberOfFailedOrders: this.numberOfFailedOrders.toString(),
    });
  }

  onItemsDetails(): void {
    /* eslint-enable */
    this.dialog.open(OrderItemDialogComponent, {
      width: '950px',
      data: {
        order: this.failedOrder,
        viewProducts: true,
        draft: this.draft,
      },
    });
  }
}
