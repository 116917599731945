<div class="tab-selector" *ngIf="shouldShowMerchantLeads || shouldShowTeleSales">
  <ng-container *ngIf="shouldShowMerchantLeads">
    <div class="orderlist-type" *ngFor="let orderType of orderListType">
      <button
        [ngClass]="{ 'active-order-tab': orderTypeActive === orderType.value }"
        (click)="switchOrderType(orderType.value)"
      >
        <img [src]="orderType.icon" />
        <span>{{ 'ORDERS_PAGE.TABS.' + orderType.key | translate }}</span>
      </button>
    </div>
  </ng-container>
  <button
    *ngIf="shouldShowTeleSales"
    class="btn order-preferences__button caption2--medium"
    (click)="openOrderPreferencesDialog()"
  >
    <i class="icon icon-nut order-preferences__icon"></i>
    <div class="order-preferences__text-container">
      <p class="order-preferences__label">{{ 'ORDERS_PAGE.BETA_FEATURE' | translate }}</p>
      <p class="order-preferences__text">{{ 'ORDERS_PAGE.ORDER_PREFERENCES' | translate }}</p>
    </div>
  </button>
</div>
<div class="container-div" *ngIf="orderTypeActive === orderTypeTaager">
  <p class="title">{{ 'ORDERS_PAGE.TITLE' | translate }}</p>

  <app-info-alert></app-info-alert>

  <app-order-filters
    (searchTriggered)="onSearchClicked($event)"
    [resetTriggered]="triggerFilterReset"
    [isChildOrderTabActive]="activeOrdersTab === ordersTabs.CHILD_ORDERS_TAB"
    [isEnglishLanguage]="isEnglishLanguage"
    [failedOrdersTabSelected]="activeOrdersTab === ordersTabs.FAILED_ORDERS_TAB"
  ></app-order-filters>
  <div class="tabs-container">
    <div class="order-tabs-container">
      <button
        *ngFor="let tab of ordersTabsArray"
        (click)="orderTabClicked(tab.name)"
        [ngClass]="{ 'selected-tab': activeOrdersTab === tab.name }"
        class="tab body2--medium"
      >
        {{ 'ORDERS_PAGE.' + tab.key | translate }}
        <p
          *ngIf="tab.hasNumberOfOrders && numberOfFailedOrders > 0"
          class="failed-orders-number caption1--medium"
        >
          {{ numberOfFailedOrders }}
        </p>
      </button>
    </div>
    <div class="excel-tooltip" *ngIf="activeOrdersTab !== ordersTabs.FAILED_ORDERS_TAB">
      <button class="btn btn-excel" (click)="viewMyOrdersExtract()">
        <img loading="lazy" class="excel-i" src="../../../assets/img/excel-white.svg" />
        {{ 'ORDERS_PAGE.DOWNLOAD_EXCEL_SHEET' | translate }}
      </button>
      <span class="tooltip-text">
        {{ 'ORDERS_PAGE.EXCEL_SHEET_TOOLTIP' | translate }}
      </span>
    </div>
  </div>
  <loader [loading]="loading"></loader>

  <div *ngIf="activeOrdersTab !== ordersTabs.CHILD_ORDERS_TAB">
    <app-order-overview
      *ngFor="let order of orders"
      [order]="order"
      (cancelOrderDialogClosed)="getOrders()"
    ></app-order-overview>
  </div>
  <div *ngIf="activeOrdersTab === ordersTabs.FAILED_ORDERS_TAB && !loading">
    <app-failed-order-overview
      *ngIf="numberOfFailedOrders === 0"
      [numberOfFailedOrders]="numberOfFailedOrders"
    ></app-failed-order-overview>
    <app-failed-order-overview
      *ngFor="let order of failedOrders"
      [failedOrder]="order"
      [numberOfFailedOrders]="numberOfFailedOrders"
    ></app-failed-order-overview>
  </div>
  <div *ngIf="activeOrdersTab === ordersTabs.CHILD_ORDERS_TAB">
    <div class="empty-message text-center" *ngIf="!loading && childOrders.length === 0">
      <img src="assets/img/empty-leads.svg" />
      <p class="body1--bold content-main-color">
        {{ 'CHILD_ORDERS.NO_ORDERS' | translate }}
      </p>
      <p class="body2--regular content-medium-color">
        {{ 'CHILD_ORDERS.WILL_DISPLAY' | translate }}
      </p>
    </div>

    <app-child-order-overview
      *ngFor="let childOrder of childOrders; index as idx"
      [childOrder]="childOrder"
    ></app-child-order-overview>
  </div>

  <div *ngIf="!loading" class="row justify-content-center paginate">
    <div *ngIf="showPagination" class="col-xs-6">
      <div class="pagination-wrapper">
        <app-taager-pagination
          [boundaryLinks]="showBoundaryLinks"
          [totalItems]="noOfItems"
          [currentPage]="currentPage"
          [itemsPerPage]="maxItemPerPage"
          [maxSize]="maxItemPerPage"
          [previousText]="' '"
          [nextText]="' '"
          [firstText]="'الأولى'"
          [lastText]="'الأخيرة'"
          [scrollToTop$]="scrollToTop$"
          [scope]="'orders'"
          (pageChanged$)="pageChanged($event)"
        ></app-taager-pagination>
      </div>
    </div>
  </div>
</div>
<div class="container-div" *ngIf="orderTypeActive === orderTypeLead">
  <app-leads-list></app-leads-list>
</div>
