import { Clipboard } from '@angular/cdk/clipboard';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LeadModel } from 'src/app/core/domain/lead.model';
import { LeadMapperPipe } from 'src/app/presentation/shared/pipes/lead-status-mapper.pipe';

@Component({
  selector: 'app-lead-card',
  templateUrl: './lead-card.component.html',
  styleUrls: ['./lead-card.component.scss'],
  standalone: true,
  imports: [NgClass, NgFor, TranslateModule, NgIf, LeadMapperPipe],
})
export class LeadCardComponent implements OnInit {
  @Input() lead: LeadModel;

  public cardFields: {
    title: string;
    value: string;
    class: string;
  }[];

  public loading = false;

  constructor(
    private clipboard: Clipboard,
    private toastr: ToastrService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setCardFields();
  }

  public setCardFields(): void {
    this.cardFields = [
      {
        title: 'ORDERS_PAGE.LEADS.AD_NAME',
        value: this.lead.ad.adName,
        class: 'col-xl-3 col-lg-4, col-6',
      },
      {
        title: 'ORDERS_PAGE.LEADS.AD_ID',
        value: this.lead.ad.adId,
        class: 'col-xl-3 col-lg-4, col-6',
      },
      {
        title: 'ORDERS_PAGE.LEADS.AD_GROUP_ID',
        value: this.lead.adGroup.adGroupId,
        class: 'col-xl-3 col-lg-4, col-6',
      },
      {
        title: 'ORDERS_PAGE.LEADS.CAMPAIGN_NAME',
        value: this.lead.campaign.campaignName,
        class: 'col-xl-3 col-lg-4, col-6',
      },
      {
        title: 'ORDERS_PAGE.LEADS.CAMPAIGN_ID',
        value: this.lead.campaign.campaignId,
        class: 'col-3 mt-4',
      },
    ];
  }

  public copyOrderId(): void {
    this.clipboard.copy(this.lead.orderId);
    this.toastr.info(this._translateService.instant('ORDERS_PAGE.ORDER_ID_COPIED'));
  }
}
