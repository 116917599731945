import { Injectable } from '@angular/core';
import { user } from '@features/user/data';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { FEATURE_FLAGS } from 'src/app/presentation/shared/constants';
import { featureAttributeAssign } from 'src/app/presentation/shared/utilities/feature-attribute-assign.utility';
import { OrderPreferenceSideEffect } from './order-preference.side-effects';
import { OrderPreferenceViewEvents } from './order-preference.view-events';
import { OrderPreferenceViewState } from './order-preference.view-state';

@Injectable()
export class OrderPreferencePresenter extends BasePresenter<
  OrderPreferenceViewState,
  OrderPreferenceViewEvents,
  OrderPreferenceSideEffect
> {
  constructor(private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase) {
    super();
  }

  protected defaultViewState(): OrderPreferenceViewState {
    return {
      shouldShowTamaraPreference: false,
      loading: true,
    };
  }

  protected onViewEvent(event: OrderPreferenceViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this._getFeatureAttributeUseCase.execute(FEATURE_FLAGS.TAMARA_PREFERENCE).subscribe({
          next: (attribute) => {
            const shouldShowTamaraPreference = featureAttributeAssign(attribute, user.id);
            this.updateViewState({
              ...this.viewState,
              shouldShowTamaraPreference,
              loading: false,
            });
          },
        });
      }
    }
  }
}
