<p>{{ childOrder.orderId }}</p>
<div *ngIf="childOrder" class="main">
  <div class="title">
    <div class="code-title">{{ 'ORDERS_PAGE.ORDER_CODE' | translate }}</div>
    <div class="code-value">{{ childOrder.orderID }}</div>
    <div class="date-title">{{ 'ORDERS_PAGE.ORDER_STATUS' | translate }}</div>
    <button
      class="btn status-badge"
      [ngClass]="{
        received: statusStage === 'received',
        delivered: statusStage === 'delivered',
        cancelled: statusStage === 'cancelled',
        pending: statusStage === 'pending'
      }"
    >
      {{ getChildOrderStatus(childOrder.status) | translate }}
    </button>
    <div class="date-title">{{ 'ORDERS_PAGE.ORDER_CREATION_DATE' | translate }}</div>
    <div class="date-value">{{ convertDate(childOrder.createdAt) }}</div>
    <div class="date-title">{{ 'ORDERS_PAGE.ORDER_CONFRIMATION_DATE' | translate }}</div>
    <div class="date-value">{{ convertDate(childOrder.updatedAt) }}</div>
  </div>
  <div class="details-container">
    <div class="details">
      <div class="inline-div">
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.ORDER_TYPE' | translate }}</div>
          <div class="lab-value">{{ getChildOrderType(childOrder.orderID) | translate }}</div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_NAME' | translate }}</div>
          <div class="lab-value">{{ childOrder.receiverName }}</div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_MOBILE_NUMBER' | translate }}</div>
          <div class="lab-value">{{ childOrder.OrderPhoneNum }}</div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.PROVINCE' | translate }}</div>
          <div class="lab-value">{{ childOrder.province }}</div>
        </div>
      </div>
      <div class="inline-div">
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_ADDRESS' | translate }}</div>
          <div class="lab-value">{{ childOrder.streetName }}</div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.SHIPPED_TO' | translate }}</div>
          <div class="lab-value">{{ childOrderCountry.arabicName }}</div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.ORDER_NOTES' | translate }}</div>
          <div class="lab-value">
            {{ childOrder.notes || ('ORDERS_PAGE.NO_NOTES_LABEL' | translate) }}
          </div>
        </div>
        <div
          *ngIf="childOrder.status === 'delivery_suspended' || childOrder.status === 'cancel'"
          class="unit"
        >
          <div class="lab-title">{{ 'ORDERS_PAGE.SUSPENSION_REASON' | translate }}</div>
          <div class="lab-value">
            {{ matchDeliverySuspendedReason(childOrder.deliverySuspendedReason) }}
          </div>
        </div>
      </div>
    </div>
    <div class="status">
      <button class="btn btn-viewOrder" (click)="onChildOrdersDetails()">
        {{ 'ORDERS_PAGE.SHOW_ORDER_BTN_LABEL' | translate }}
      </button>
      <button class="btn btn-message" (click)="openChatModel(childOrder)">
        {{ 'ORDERS_PAGE.SEND_MESSAGE_BTN_LABEL' | translate }}
      </button>
    </div>
  </div>
</div>
