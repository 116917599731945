import { Pipe, PipeTransform } from '@angular/core';
import { LeadStatus } from 'src/app/core/domain/lead.model';

@Pipe({
  name: 'leadMapper',
  standalone: true,
})
export class LeadMapperPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case LeadStatus.lead_received:
        return 'ORDERS_PAGE.LEADS.LEAD_VERIFYING';
      case LeadStatus.lead_converted:
        return 'ORDERS_PAGE.LEADS.LEAD_CONFIRMED';
      case LeadStatus.lead_out_of_stock:
        return 'ORDERS_PAGE.LEADS.LEAD_OUT_OF_STOCK';
      case LeadStatus.lead_rejected:
        return 'ORDERS_PAGE.LEADS.LEAD_REJECTED';
      case LeadStatus.lead_suspended:
        return 'ORDERS_PAGE.LEADS.LEAD_SUSPENDED';
      default:
        return value
          ?.split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
    }
  }
}
