<div class="wrapper {{ scope }}">
  <pagination
    [boundaryLinks]="boundaryLinks"
    [totalItems]="totalItems"
    [(ngModel)]="currentPage"
    [itemsPerPage]="itemsPerPage"
    [maxSize]="maxSize"
    [previousText]="previousText"
    [nextText]="nextText"
    [firstText]="firstText"
    [lastText]="lastText"
    (pageChanged)="pageChanged($event)"
  >
  </pagination>
</div>
