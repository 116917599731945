import { NgClass, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { UpdateProfileUseCase } from 'src/app/core/usecases/user/update-profile-usecase';
import { BaseComponent } from '../../base/base.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { ProfileService } from '../../shared/services/profile.service';
import { OrderPreferenceSideEffect } from './presentation/order-preference.side-effects';
import { OrderPreferenceViewEvents } from './presentation/order-preference.view-events';
import { OrderPreferenceViewState } from './presentation/order-preference.view-state';
import { OrderPreferencePresenter } from './presentation/order-preferences.presenter';

@Component({
  selector: 'app-global-order-preferences-dialog',
  templateUrl: './global-order-preferences-dialog.component.html',
  styleUrls: ['./global-order-preferences-dialog.component.scss'],
  standalone: true,
  providers: [OrderPreferencePresenter],
  imports: [
    NgClass,
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    NgIf,
  ],
})
export class GlobalOrderPreferencesDialogComponent extends BaseComponent<
  OrderPreferencePresenter,
  OrderPreferenceViewState,
  OrderPreferenceViewEvents,
  OrderPreferenceSideEffect
> {
  public hasTeleSalesEnabled: boolean;

  public enableTamaraPayment: boolean;

  public shouldShowTamaraPreference = false;

  public isLoading: boolean;

  public presenter: OrderPreferencePresenter = inject(OrderPreferencePresenter);

  constructor(
    private _dialogRef: MatDialogRef<GlobalOrderPreferencesDialogComponent>,
    private _updateProfileUseCase: UpdateProfileUseCase,
    private _toastService: ToastrService,
    private _translateService: TranslateService,
    private _profileService: ProfileService,
  ) {
    super();
  }

  public onInit(): void {
    this.isLoading = true;
    this._profileService
      .getUserProfile()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(({ data }) => {
        this.hasTeleSalesEnabled = data.hasTeleSalesEnabled !== false;
        this.enableTamaraPayment = data.enableTamaraPayment !== false;
      });

    this.presenter.emitViewEvent({ type: 'Init' });
  }

  submitPreference(): void {
    this.isLoading = true;
    this._updateProfileUseCase
      .execute({
        hasTeleSalesEnabled: this.hasTeleSalesEnabled,
        enableTamaraPayment: this.enableTamaraPayment,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this._toastService.success(
            this._translateService.instant('ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.SUCCESS_MESSAGE'),
          );
          this._dialogRef.close();
        },
        error: () => {
          this._translateService.instant('ERRORS.GENERIC_ERROR_MESSAGE');
        },
      });
  }

  closeDialog(): void {
    this._dialogRef.close();
  }
}
