export const LEADS_STATUS_LIST = [
  { name: 'ORDERS_PAGE.LEADS.LEAD_VERIFYING', value: 'received' },
  { name: 'ORDERS_PAGE.LEADS.LEAD_SUSPENDED', value: 'suspended' },
  { name: 'ORDERS_PAGE.LEADS.LEAD_REJECTED', value: 'rejected' },
  { name: 'ORDERS_PAGE.LEADS.LEAD_CONFIRMED', value: 'converted' },
  { name: 'ORDERS_PAGE.LEADS.LEAD_OUT_OF_STOCK', value: 'out_of_stock' },
];

export const ADDRESS_LENGTH_LIST = [
  { name: 'Long', value: 'long' },
  { name: 'Short', value: 'short' },
];
