<div class="order-filters">
  <form [formGroup]="leadSearchForm" (ngSubmit)="onClickSearchButton()">
    <div class="order-filters__row">
      <mat-form-field appearance="outline" class="order-filters__long-input">
        <input
          matInput
          formControlName="searchKey"
          [placeholder]="'ORDERS_PAGE.LEADS.FILTERS.LEAD_ID' | translate"
        />
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="order-filters__select">
        <mat-select
          formControlName="status"
          [placeholder]="'ORDERS_PAGE.LEADS.FILTERS.LEAD_STATUS' | translate"
        >
          <mat-option *ngFor="let status of statusList" [value]="status.value">{{
            status.name | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button class="order-filters__button order-filters__button--solid">
        {{ 'ORDERS_PAGE.FILTERS.SEARCH_BTN_LABEL' | translate }}
      </button>

      <button
        class="order-filters__button order-filters__button--stroked"
        (click)="onClearFilters()"
        type="button"
      >
        {{ 'ORDERS_PAGE.FILTERS.NEW_BTN_LABEL' | translate }}
      </button>
    </div>
  </form>
</div>
