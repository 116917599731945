<loader [loading]="isLoading" class="loader"></loader>
<div class="order-preferences" [ngClass]="{ 'order-preferences--loading': isLoading }">
  <p class="order-preferences__title heading2--bold">
    {{ 'ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.TITLE' | translate }}
    <span class="order-preferences__beta-label caption2--medium"
      >{{ 'ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.BETA_LABEL' | translate }}
    </span>
    <span class="order-preferences__global-setting-label caption1--regular">{{
      'ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.GLOBAL_SETTING' | translate
    }}</span>
  </p>
  <p class="order-preferences__sub-title caption2--regular">
    {{ 'ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.SUB_TITLE' | translate }}
  </p>
  <div class="order-preferences__feature-container">
    <mat-checkbox [(ngModel)]="hasTeleSalesEnabled" [disabled]="isLoading"></mat-checkbox>
    <div class="order-preferences__feature">
      <p class="order-preferences__feature-name caption1--bold">
        {{ 'ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.APPLY_TELE_SALES' | translate }}
      </p>
      <p class="orer-preferences__feature-description caption2--regular">
        {{ 'ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.TELE_SALES_DESCRIPTION' | translate }}
      </p>
    </div>
  </div>
  <ng-container *ngIf="!viewState.loading">
    <div class="order-preferences__feature-container" *ngIf="viewState.shouldShowTamaraPreference">
      <mat-checkbox [(ngModel)]="enableTamaraPayment" [disabled]="isLoading"></mat-checkbox>
      <div class="order-preferences__feature">
        <p class="order-preferences__feature-name caption1--bold">
          {{ 'ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.APPLY_TAMARA' | translate }}
        </p>
        <p class="orer-preferences__feature-description caption2--regular">
          {{ 'ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.APPLY_TAMARA_DESCRIPTION' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
  <div class="order-preferences__action-buttons">
    <button
      class="order-preferences__submit body2--bold"
      (click)="submitPreference()"
      [disabled]="isLoading"
    >
      {{ 'ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.SAVE' | translate }}
    </button>
    <button
      class="order-preferences__cancel body2--bold"
      (click)="closeDialog()"
      [disabled]="isLoading"
    >
      {{ 'ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.CANCEL' | translate }}
    </button>
  </div>
</div>
