import { NgFor, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { LEADS_STATUS_LIST } from '../../shared/constants/lead';

@Component({
  selector: 'app-lead-filters',
  templateUrl: './lead-filters.component.html',
  styleUrls: ['./lead-filters.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgStyle,
  ],
})
export class LeadFiltersComponent {
  public leadSearchForm: FormGroup;

  @Input() resetTriggered: Subject<boolean>;

  @Output() searchTriggered: EventEmitter<any> = new EventEmitter();

  public statusList = LEADS_STATUS_LIST;

  constructor() {
    this.leadSearchForm = new FormGroup({
      searchKey: new FormControl(null),
      status: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.resetTriggered.subscribe({
      next: () => {
        this.leadSearchForm.reset();
      },
    });
  }

  public onClickSearchButton(): void {
    this.searchTriggered.emit(this.leadSearchForm.value);
  }

  public onClearFilters(): void {
    this.leadSearchForm.reset();
    this.searchTriggered.emit(this.leadSearchForm.value);
  }
}
