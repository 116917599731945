import { NgClass, NgIf } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
// Ignoring the linting check because this comes from a Kotlin Library
// @ts-ignore
import { getCountryFromIsoCode3 } from '@taager-experience-shared/country-resolver';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { ORDER_STATUSES } from 'src/app/presentation/shared/constants';
import { Country } from 'src/app/presentation/shared/interfaces/countries';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { ChildOrderItemDialogComponent } from '../child-order-item-dialog/child-order-item-dialog.component';

@Component({
  selector: 'app-child-order-overview',
  templateUrl: './child-order-overview.component.html',
  styleUrls: ['./child-order-overview.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, TranslateModule],
})
export class ChildOrderOverviewComponent implements OnInit {
  @Input() childOrder: any;

  public statusStage = '';

  public isMobile: boolean;

  public childOrderCountry: Country;

  private _siteTranslateService = inject(SiteTranslateService);

  constructor(
    private dialog: MatDialog,
    private responsiveService: ResponsiveService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {}

  ngOnInit(): void {
    this.getIsMobile();
    this.getChildOrderStatus(this.childOrder.status);
    this.childOrderCountry = getCountryFromIsoCode3(this.childOrder.countryIsoCode || 'EGY');
  }

  getChildOrderStatus(childOrderStatus: string): string {
    const statusObject = ORDER_STATUSES.ALL_STATUSES.filter(
      (status) => status.statusInEnglish === childOrderStatus,
    );
    return statusObject.length === 0 ? childOrderStatus : statusObject[0].statusTranslationKey;
  }

  getIsMobile(): void {
    this.responsiveService.getMobileStatus().subscribe({
      next: (isMobile) => {
        this.isMobile = isMobile;
      },
    });
  }

  convertDate(mongo: any): string {
    if (mongo) {
      const date = new Date(mongo);
      return date.toLocaleDateString('en-US');
    }
    return '';
  }

  getChildOrderType(childOrderId: string): string {
    return ORDER_STATUSES.CHILD_ORDER_TYPE_MAP.get(childOrderId.charAt(0)) || '';
  }

  onChildOrdersDetails(): void {
    const childOrders: any[] = [];
    childOrders.push(this.childOrder);
    const parentOrderData = {
      orderID: this.childOrder.pOrderId,
      receiverName: this.childOrder.receiverName,
      phoneNumber: this.childOrder.OrderPhoneNum,
    };
    const dialogRef = this.dialog.open(ChildOrderItemDialogComponent, {
      width: '950px',
      data: { childOrders, order: parentOrderData, statusStage: this.statusStage },
      direction: this._siteTranslateService.currentDir,
    });

    dialogRef.afterClosed().subscribe({ next: (result) => {} });
  }

  openChatModel(orderObj: any): void {
    /* eslint-disable */
    this._logMixpanelEventUseCase.execute({
      eventName: 'Open_order_message',
      payload: {
        'Order Id': orderObj.orderID,
        Status: orderObj.status,
        'Shipping Company':
          orderObj.shippingInfo && orderObj.shippingInfo.company
            ? orderObj.shippingInfo.company
            : 'No company',
      },
    });
    /* eslint-enable */
  }

  matchDeliverySuspendedReason(reason: string): string {
    return ORDER_STATUSES.DELIVERY_SUSPENSION_REASONS_TRANSLATION[reason] || '';
  }
}
